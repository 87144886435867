<template>
  <div class="page-partner animate__animated animate__fadeIn pt-2">

    <div class="text-center">
      <img
        :src="`${this.$urlBucket}/form/assets/${referrer}-banner.png`"
        class="img-fluid mb-4"
        style="display: none; max-height: 330px"
        @load="
          (e) => {
            e.target.style.display = 'unset';
            hideSpinner('spinner-banner');
          }
        "
        @error="(e) => (e.target.src = `${this.$urlBucket}/form/assets/front-form.png`)"
      />
    </div>

    <div id="spinner-banner" class="text-center">
      <img src="/assets/img/spinner-loading.gif" width="100px" alt="" />
    </div>

    <div class="d-flex justify-content-center">
      <div class="items-desc d-flex align-items-center">
        <img
          :src="`${this.$urlBucket}/form/assets/calendar.png`"
          class="img-fluid items-partner"
          alt=""
          srcset=""
        />
        <p class="mb-0">Use SlothMove any time, before or after your move</p>
      </div>
    </div>
    <div class="d-flex justify-content-center">
      <div class="items-desc d-flex align-items-center">
        <img
          :src="`${this.$urlBucket}/form/assets/energy.png`"
          class="img-fluid items-partner"
          alt=""
          srcset=""
        />
        <p class="mb-0">Update your address with everyone in 3 minutes</p>
      </div>
    </div>
    <div class="d-flex justify-content-center">
      <div class="items-desc d-flex align-items-center mb-4">
        <img
          :src="`${this.$urlBucket}/form/assets/odometer.png`"
          class="img-fluid items-partner"
          alt=""
          srcset=""
        />
        <p class="mb-0">Track the progress of your updates in your dashboard</p>
      </div>
    </div>

    <div class="d-flex justify-content-center" v-if="referrer=='onTheMarket'">
      <div class="label-pages d-flex p-2 px-3 align-items-center" style="max-width: 330px">
        <img :src="require('@/assets/check-y.png')" width="30px" class="ms-2 me-2" alt="" />
        <p class="ms-1 mb-0" style="font-size: 13px">
          SlothMove address changer is available to you through
          <span style="color: #b5a855">{{ capitalized(referrer) }}</span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { navigateTo } from "../router/index.js";

// @ is an alias to /src

export default {
  name: "PagePartner",
  components: {},
  data() {
    return {};
  },
  methods: {
    hideSpinner(id) {
      document.getElementById(id).style.display = "none";
    },
    capitalized(word) {
      return word.charAt(0).toUpperCase() + word.slice(1);
    },
  },
  mounted() {
    this.$store.commit("setField", [`messageButton`, "GET STARTED"]);

    // this.$store.commit("setField", ["titleNavbar", ""]);
    // this.$store.commit("setField", ["step1", true]);

    const validatePage = () => {
      let route = "/";

      navigateTo(route);
    };

    document.getElementById("mainButton").onclick = (ev) => {
      validatePage();
    };

    document.onkeydown = (e) => {
      // if (e.key == "ArrowLeft" && document.activeElement.tagName !== "INPUT") {
      //   this.$router.back();
      // }

      if (e.key == "ArrowRight" && document.activeElement.tagName !== "INPUT") {
        validatePage();
      }
    };
  },

  computed: {
    ...mapState([""]),
    ...mapState("formStore", ["referrer", "baseURL", "urlBucket"]),
  },
};
</script>
<style lang="scss">
.page-partner {
  .items-desc {
    max-width: 330px;
  }

  .items-partner {
    max-height: 45px;
    margin: 1em;
  }
}
</style>
